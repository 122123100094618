import {useState, useContext, createContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OrderTypes, PICKUP_FULFILLMENT_TYPES} from '@hy-vee/shared-utils-aisles-online';

import {LocationEcommerceStatus} from 'autogen/globalTypes';

import {TIME_SLOT, LOCATION, REVIEW, FULFILLMENT, SKIPPED} from '../enums/reservation-stepper-enums';
import {getReservedTimeOnCart} from '../utils/view-helpers/fulfillment-view-helpers';
import {loadFulfillmentChangePageData} from '../action-creators';
import {addPropsToChildren} from '../utils/view-helpers/view-helpers';

const reservationContext = createContext();

const useProvideReservation = () => {
    const dispatch = useDispatch();
    const reservationStepperIndexFromState = useSelector((state) => state.reservationStepperIndex);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const [fulfillmentLocationInactive, setFulfillmentLocationInactive] = useState(false);
    const [fulfillmentType, setFulfillmentType] = useState();
    const [pickupLocation, setPickupLocation] = useState();
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [fulfillmentLocationId, setFulfillmentLocationId] = useState();
    const [fulfillmentTime, setFulfillmentTime] = useState();
    const [basketContinuityWasCalled, setBasketContinuityWasCalled] = useState(false);
    const [isUpdatingCart, setIsUpdatingCart] = useState(false);
    const [storeId, setStoreId] = useState();
    const [doesPreventAlcoholChange, setDoesPreventAlcoholChange] = useState(false);
    const [cartFulfillmentTime, setCartFulfillmentTime] = useState();
    const [cartContainsAlcohol, setCartContainsAlcohol] = useState(false);
    const [deliveryLocationCanFulfillAlcohol, setDeliveryLocationCanFulfillAlcohol] = useState(false);
    const [isStoreEcommerceInactive, setIsStoreEcommerceInactive] = useState(false);

    const basketContinuityCall = (deliveryAddressId, preventAlcohol) => {
        const queryParams = deliveryAddressId
            ? `?changeFulfillmentLocationTo=${fulfillmentLocationId}&changeDeliveryAddressTo=${deliveryAddressId}&preventAlcohol=${preventAlcohol}`
            : `?changeFulfillmentLocationTo=${fulfillmentLocationId}&preventAlcohol=${preventAlcohol}`;

        dispatch(
            loadFulfillmentChangePageData({
                queryParams
            })
        );
    };

    const onSetSelectedIndex = (index, preventAlcohol) => {
        if (index === REVIEW) {
            if (fulfillmentType === OrderTypes.DELIVERY) {
                basketContinuityCall(deliveryLocation.deliveryAddressId, preventAlcohol);
                setBasketContinuityWasCalled(true);
            } else if (PICKUP_FULFILLMENT_TYPES.includes(fulfillmentType)) {
                basketContinuityCall(undefined, preventAlcohol);
                setBasketContinuityWasCalled(true);
            }
        }

        setSelectedIndex([index]);
    };

    const onSelectPickup = () => {
        setFulfillmentType(OrderTypes.PICKUP);
        setPickupLocation(null);
        setDeliveryLocation(null);
        setFulfillmentTime(null);
        setFulfillmentLocationId(null);
        setBasketContinuityWasCalled(false);
        setSelectedIndex([LOCATION]);
        setIsStoreEcommerceInactive(false);
    };

    const onSelectDelivery = () => {
        setFulfillmentType(OrderTypes.DELIVERY);
        setPickupLocation(null);
        setDeliveryLocation(null);
        setFulfillmentTime(null);
        setFulfillmentLocationId(null);
        setBasketContinuityWasCalled(false);
        setSelectedIndex([LOCATION]);
    };

    const onSelectPickupLocation = (newPickupLocation) => {
        setPickupLocation(newPickupLocation);
        setDeliveryLocationCanFulfillAlcohol(true);
        setFulfillmentType(newPickupLocation.fulfillmentType || fulfillmentType);
        setStoreId(newPickupLocation.storeId || newPickupLocation.fulfillmentStoreId);
        setFulfillmentTime(null);
        setFulfillmentLocationId(newPickupLocation.fulfillmentLocationId);
        setBasketContinuityWasCalled(false);
        setSelectedIndex([TIME_SLOT]);
        setIsStoreEcommerceInactive(
            newPickupLocation.fulfillmentStoreLocation?.ecommerceStatus === LocationEcommerceStatus.INACTIVE
        );
    };

    const onSelectDeliveryLocation = (newDeliveryAddress, newFulfillmentLocationId) => {
        const newStore = newDeliveryAddress.fulfillmentLocations.find(
            (fulfillmentLocation) => fulfillmentLocation.fulfillmentLocationId === newFulfillmentLocationId
        );

        setDeliveryLocation(newDeliveryAddress);
        setStoreId(newStore?.fulfillmentStoreId);
        setDeliveryLocationCanFulfillAlcohol(newStore?.canFulfillAlcohol);
        setFulfillmentTime(null);
        setBasketContinuityWasCalled(false);

        if (newDeliveryAddress.fulfillmentLocations?.length) {
            setFulfillmentLocationId(newFulfillmentLocationId);
        }

        setSelectedIndex([TIME_SLOT]);
    };

    const onSelectFulfillmentTime = (newFulfillmentTime) => {
        setDoesPreventAlcoholChange(
            cartFulfillmentTime ? newFulfillmentTime.preventAlcohol !== cartFulfillmentTime.preventAlcohol : false
        );
        setFulfillmentTime(newFulfillmentTime);
        setBasketContinuityWasCalled(false);
        onSetSelectedIndex(REVIEW, newFulfillmentTime.preventAlcohol);
    };

    const setInitialLocation = (cart) => {
        setStoreId(cart.fulfillmentLocation?.fulfillmentStore.storeId);

        if (cart.fulfillmentType === OrderTypes.DELIVERY) {
            setDeliveryLocation(cart.deliveryAddress);
            setDeliveryLocationCanFulfillAlcohol(cart.fulfillmentLocation.canFulfillAlcohol);
        } else if (PICKUP_FULFILLMENT_TYPES.includes(cart.fulfillmentType)) {
            const newPickupLocation = cart.pickupLocation
                ? {
                      ...cart.fulfillmentLocation.fulfillmentStore,
                      hasPasscode: Boolean(cart.pickupLocation.passcode),
                      name: cart.pickupLocation.name,
                      pickupLocationId: cart.pickupLocation.pickupLocationId
                  }
                : {
                      requireSeparateAlcoholOrder: cart.fulfillmentLocation.requireSeparateAlcoholOrder,
                      ...cart.fulfillmentLocation.fulfillmentStore
                  };

            setPickupLocation(newPickupLocation);
            setDeliveryLocationCanFulfillAlcohol(true);
        }

        setFulfillmentLocationId(cart.fulfillmentLocation?.fulfillmentLocationId);
    };

    const setInitialFulfillmentTime = (cart, fulfillmentTimes) => {
        const newFulfillmentTime = getReservedTimeOnCart(fulfillmentTimes, cart);

        if (newFulfillmentTime && newFulfillmentTime.isAvailable) {
            setFulfillmentTime(newFulfillmentTime);
        }

        return newFulfillmentTime;
    };

    const setInitiallySelectedIndex = (newFulfillmentTime, newFulfillmentType, reservationStepperIndex) => {
        const currentFulfillmentTime = newFulfillmentTime || fulfillmentTime;
        const currentFulfillmentType = newFulfillmentType || fulfillmentType;
        const startingIndex = Number.isInteger(reservationStepperIndexFromState)
            ? reservationStepperIndexFromState
            : reservationStepperIndex;

        if (!isNaN(startingIndex)) {
            setSelectedIndex([startingIndex]);
        } else if (!currentFulfillmentType || currentFulfillmentType === OrderTypes.NOT_SELECTED) {
            setSelectedIndex([FULFILLMENT]);
        } else if (
            !currentFulfillmentTime ||
            currentFulfillmentTime === SKIPPED ||
            !currentFulfillmentTime.isAvailable
        ) {
            setSelectedIndex([TIME_SLOT]);
        } else {
            setSelectedIndex([REVIEW]);
        }
    };

    const setInitialState = (cart, fulfillmentTimes, reservationStepperIndex) => {
        if (!cart || !fulfillmentTimes) {
            return null;
        }

        if (!cart.fulfillmentLocation) {
            setSelectedIndex([FULFILLMENT]);

            return null;
        }

        setFulfillmentType(cart.fulfillmentType);
        setInitialLocation(cart);

        const newFulfillmentTime = setInitialFulfillmentTime(cart, fulfillmentTimes);

        setCartFulfillmentTime(newFulfillmentTime);

        setInitiallySelectedIndex(newFulfillmentTime, cart.fulfillmentType, reservationStepperIndex);

        return null;
    };

    return {
        basketContinuityWasCalled,
        cartContainsAlcohol,
        deliveryLocation,
        deliveryLocationCanFulfillAlcohol,
        doesPreventAlcoholChange,
        fulfillmentLocationId,
        fulfillmentLocationInactive,
        fulfillmentTime,
        fulfillmentType,
        isStoreEcommerceInactive,
        isUpdatingCart,
        onSelectDelivery,
        onSelectDeliveryLocation,
        onSelectFulfillmentTime,
        onSelectPickup,
        onSelectPickupLocation,
        onSetSelectedIndex,
        pickupLocation,
        selectedIndex,
        setCartContainsAlcohol,
        setFulfillmentLocationInactive,
        setFulfillmentTime,
        setInitiallySelectedIndex,
        setInitialState,
        setIsStoreEcommerceInactive,
        setIsUpdatingCart,
        storeId
    };
};

export const ReservationProvider = (props) => {
    const reservation = useProvideReservation();

    return (
        <reservationContext.Provider value={reservation}>
            {addPropsToChildren(props.children, props)}
        </reservationContext.Provider>
    );
};

export const useReservation = () => {
    return useContext(reservationContext);
};
